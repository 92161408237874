<template>
  <div class="documents__article" :class="{ open: isOpen }" @click="handleClick" ref="root">
    <slot name="items" />
    <div>
      <span class="documents__detail-toggle" :class="{ 'hide-lg': isHiddenOnLarge }"></span>
    </div>
    <div class="documents__detail" :class="{ 'hide-lg': isHiddenOnLarge }">
      <slot name="details" />
    </div>
  </div>
</template>

<script>
import { CycleIcon } from '@/components/elements'

export default {
  name: 'InboxItem',
  components: { CycleIcon },
  props: {
    isOpen: Boolean,
    isHiddenOnLarge: Boolean
  },
  methods: {
    handleClick(event) {
      this.$emit('open', event)
    }
  }
}
</script>
