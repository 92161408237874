<template>
  <div class="documents-container">
    <BusyIndicator v-if="isLoading" />
    <EmptyTablePlaceholder
      v-if="!isLoading && documents.length === 0"
      :no-results-text="textContent.noResultsText"
    />
    <div v-if="documents.length > 0" class="documents--inbox-wrapper">
      <template>
        <div class="documents documents--inbox">
          <DocumentsHeading>
            <div class="hide-S"></div>
            <div><CycleHtml :html="setColumnTitle(textContent.columnTitle1)" inline /></div>
            <div class="">
              <CycleHtml :html="setColumnTitle(textContent.columnTitle2)" inline />
            </div>
            <div class="hide-S">
              <CycleHtml :html="setColumnTitle(textContent.columnTitle3)" inline />
            </div>
            <div></div>
          </DocumentsHeading>

          <InboxItem
            v-for="(document, detailIndex) in documents"
            :key="detailIndex"
            :is-open="selectedDetails.includes(detailIndex)"
            :is-hidden-on-large="!showReplyMessage"
            @open="handleDetailClick(detailIndex, $event)"
            :class="documentsItemClass(document.id)"
          >
            <template #items>
              <div class="hide-S">
                <CycleIcon
                  :modifiers="getDocumentStatusIcon(document.id)"
                  v-if="!hideDocumentStatus(document.id)"
                />
              </div>
              <div>{{ document.date | cycleDate({ locale }) }}</div>
              <div class="">{{ document.information }}</div>
              <div class="hide-S">
                <CycleLinkIcon
                  v-if="document.id"
                  modifiers="download"
                  :href="document.url"
                  target="_blank"
                  @click.native.stop="updateDocumentStatus(document.id, DocumentStatusTypes.Read)"
                >
                  {{ textContent.downloadLinkText }}
                </CycleLinkIcon>
              </div>
            </template>
            <template #details>
              <CycleLinkIcon
                v-if="document.id"
                modifiers="download"
                class="showonmobile"
                :href="document.url"
                target="_blank"
                :key="document.id + '_bueton'"
                @click.native.stop="updateDocumentStatus(document.id, DocumentStatusTypes.Read)"
              >
                {{ textContent.downloadLinkText }}
              </CycleLinkIcon>

              <form
                class="form"
                @submit.prevent="onResponseSubmit(document)"
                v-if="!responseStatus[document.id] && showReplyMessage"
              >
                <label class="form__label">
                  <span class="showonmobile">
                    {{ textContent.replyTitleMobile }}
                    <!-- <a aria-role="button" tabindex="1" class="tooltip"></a> -->
                  </span>
                  <span class="hide-S">
                    {{ textContent.replyTitleDesktop }}
                    <!-- <a aria-role="button" tabindex="1" class="tooltip"></a> -->
                  </span>
                </label>
                <textarea
                  type="textarea"
                  rows="6"
                  class="form__input"
                  v-model="responseText[document.id]"
                ></textarea>
                <div class="form__buttons">
                  <CycleButton @click.stop>{{ textContent.send }}</CycleButton>
                </div>
              </form>
              <BusyIndicator v-if="responseStatus[document.id] === 0" />
              <CycleFormConfirmation status="confirmed" v-if="responseStatus[document.id] === 1">
                <CycleHtml
                  :html="textContent.postSucceededMessageText"
                  :key="document.id + '_succeeded'"
                />
              </CycleFormConfirmation>
              <CycleFormConfirmation status="error" v-if="responseStatus[document.id] === -1">
                <CycleHtml :html="textContent.postFailedMessageText" />
              </CycleFormConfirmation>
            </template>
          </InboxItem>
        </div>

        <CyclePagination
          v-if="hasPagination"
          :total-number-of-items="totalNumberOfLines"
          :number-of-items-on-page="numberOfLinesOnPage"
          :current-page="currentPage"
          :pagination-item-text="textContent.paginationItemText"
          :pagination-text="textContent.paginationText"
          @page-change="setPageNumber"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { CycleHtml } from '@/components/base'
import { CycleButton, CycleIcon } from '@/components/elements'
import { DocumentsHeading } from '@/components/atoms'
import { BusyIndicator, InboxItem, EmptyTablePlaceholder } from '@/components/molecules'
import { CyclePagination } from '@/components/cycle-pagination'
import { CycleLinkIcon } from '@/components/cycle-link'
import { CycleFormConfirmation } from '@/components/functional'
import { CycleDate } from '@/filters'
import { intlMixin } from '@/mixins'
import { DocumentStatusTypes } from './constants/my-documents-constants'
import { CycleMyDocument } from './CycleMyDocument'
import { LIST_OF_CLASS_NAMES } from './constants.js'
import { mapGetters, mapActions } from 'vuex'
import MyDocumentsState from './store'

export default {
  name: 'CycleMyDocuments',
  components: {
    CyclePagination,
    CycleButton,
    CycleLinkIcon,
    CycleMyDocument,
    CycleHtml,
    DocumentsHeading,
    InboxItem,
    EmptyTablePlaceholder,
    BusyIndicator,
    CycleFormConfirmation,
    CycleIcon
  },
  filters: {
    CycleDate
  },
  mixins: [intlMixin],
  props: {
    textContent: {
      type: Object,
      required: false,
      default: () => ({})
    },
    documents: {
      type: Array,
      required: false,
      default: () => []
    },
    totalNumberOfLines: {
      type: Number,
      required: true
    },
    numberOfLinesOnPage: {
      type: Number,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: false
    },
    responseStatus: {
      type: Object,
      default: () => {}
    },
    showReplyMessage: {
      type: Boolean,
      default: true
    },
    pageNumber: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      responseText: {},
      selectedDetails: [],
      DocumentStatusTypes
    }
  },
  beforeCreate() {
    this.$store.registerModule('myDocuments', MyDocumentsState, {
      preserveState: !!this.$store.state['myDocuments']
    })
  },
  destroyed() {
    this.$store.unregisterModule('myDocuments')
  },
  computed: {
    ...mapGetters('myDocuments', ['getDocumentStatus', 'getDocumentStatusIcon']),
    hasPagination() {
      if (this.numberOfLinesOnPage === 0) {
        return false
      }

      return this.totalNumberOfLines / this.numberOfLinesOnPage > 1
    },
    currentPage() {
      return this.pageNumber
    }
  },
  watch: {
    documents: {
      immediate: true,
      handler() {
        this.documents.forEach((document) => {
          const documentStatus = document.status
          const documentID = document.id
          this.updateDocumentStatus(documentID, documentStatus)
        })
      }
    }
  },
  methods: {
    ...mapActions('myDocuments', ['setDocumentStatus']),
    onResponseSubmit(document) {
      if (!this.responseText[document.id]) {
        return
      }

      this.$emit('response', {
        message: this.responseText[document.id],
        document
      })
      // optimistic clean-up. Error and Success paths need to be implemented in next story.
      // this.responseText[document.id] = undefined
    },
    setColumnTitle(columnTitle) {
      return columnTitle ? columnTitle : '&nbsp;'
    },
    setPageNumber(e) {
      this.$emit('page-change', e)
    },
    isPartOfClassList(event) {
      return LIST_OF_CLASS_NAMES.includes(event.target.className)
    },
    handleDetailClick(index, event) {
      if (this.isPartOfClassList(event)) return
      this.updateVisibilityOfDetail(index)
    },
    updateVisibilityOfDetail(index) {
      if (this.selectedDetails.includes(index)) {
        this.selectedDetails.splice(this.selectedDetails.indexOf(index), 1)
      } else {
        this.selectedDetails.push(index)
      }
    },
    // Do not set the DocumentStatus if the document is already read or if the document status is hidden.
    // This prevents documents appearing unread after page changes or when documents are filtered
    // or when the status should stay hidden.
    async updateDocumentStatus(documentID, documentStatus = DocumentStatusTypes.Unknown) {
      const storedDocumentStatus = this.getDocumentStatus(documentID)
      if (
        storedDocumentStatus === DocumentStatusTypes.Read ||
        storedDocumentStatus === DocumentStatusTypes.Hidden
      ) {
        return
      }

      await this.setDocumentStatus({ documentID, documentStatus })
    },
    hideDocumentStatus(documentID) {
      return (
        this.getDocumentStatus(documentID) == undefined ||
        this.getDocumentStatus(documentID) === DocumentStatusTypes.Hidden
      )
    },
    documentsItemClass(documentID) {
      return this.getDocumentStatus(documentID) === DocumentStatusTypes.Unread
        ? 'documents__item--unread'
        : ''
    }
  }
}
</script>
